.lykiocss-featured-stories-carousel > .carousel-inner > .carousel-item {
    padding-left: 2em;
    padding-bottom: 0.5em;
    background-color: #000;
    color: #fff;
    opacity: 0.55;
    border-radius: 8px;
    max-width: 35rem;
    margin-top: 1em;
    margin-bottom: 2em;
}

.lykiocss-featured-stories-carousel > .carousel-indicators {
    max-width: 8rem;
}

.lykiocss-featured-stories-carousel > .carousel-control-next {
    display: none;
}

.lykiocss-featured-stories-carousel > .carousel-control-prev {
    display: none;
}