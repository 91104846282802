.lykiocss-homestats-social-resume .lykiocss-homestats-item h1, .lykiocss-homestats-social-resume .lykiocss-homestats-item p  {
    margin: 0;
    padding: 0;
    color: #c6cad6;
}

.lykiocss-homestats-box {
    margin-top: 6em;
    margin-bottom: 6em;
    display: flex;
    align-items: center;
    justify-content: center;
}