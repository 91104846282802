.verifyaccount-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.verifyaccount-screen__title {
  text-align: center;
  margin-bottom: 1rem;
}

.verifyaccount-error-message{
  font-size: large;
  color: darkred;
}

.verifyaccount-success-message{
  font-size: large;
  color: darkgreen;
}

.verifyaccount-bottom-text{
  margin-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
  