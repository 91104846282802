.lykiocss-contentcreation-uppercase{
  text-transform: uppercase;
}

.lykiocss-contentcreation-margin-full-em {
  margin-left:1.5em;
  height: 100%;
}

.lykiocss-contentcreation-file-upload {
  display: none;
}

.lykiocss-contentcreation-custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.lykiocss-contentcreation-file-preview {
margin: 0 10px;
}

.lykiocss-contentcreation-form-row{
  color: #3A3A3A;
}

.lykioccs-contentcreation-main-content{
  margin-top: 3.1em;
}