
h1.lykiocss-page-title  {
  font-size: 5em;
  font-weight: 800;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

h1.lykiocss-slider-title  {
  font-size: 3em;
  font-weight: 800;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
}

h1.lykiocss-home-stories-title  {
  font-size: 3.5em;
  font-weight: 700;
  margin-bottom: 0;
}
/* BOOTSTRAP CAROUSEL*/

.lykiocss-home .carousel.slide {
  border-radius: 0px;
}
.lykiocss-home .carousel.slide .carousel-item {
  /*background-color: black;*/
  height: 36vh;
}
.lykiocss-home .carousel.slide .carousel-item > img {
  opacity: 0.5;
  transition: 0.3s;
  height: 24em;
  object-fit: cover;
}
.lykiocss-home .carousel.slide .carousel-item > img:hover {
  opacity: 1;
}
.lykiocss-home .carousel.slide .carousel-item > .carousel-caption {
  /*bottom: 20%;*/
  pointer-events: none;
  text-align:left;
  left: 3vw;
}
.lykiocss-home .carousel.slide .carousel-item > .carousel-caption button {
  pointer-events: all;
}

.lykiocss-home .carousel.slide .carousel-control-next, .lykiocss-home .carousel.slide .carousel-control-prev{
  width: 4%;
  align-items: end;
}

.lykiocss-home .carousel.slide .carousel-indicators [data-bs-target]{
  border-top: 4px solid white;
  border-bottom: 1px solid white;
  border-radius: var(--border-radius);;
  width: 10px;
}

/* CARDS */

.MuiCard-root.MuiPaper-root {
  background-color: transparent;
  box-shadow: none;
}
.MuiCard-root.MuiPaper-root .MuiTypography-root {
  color: var(--reversed-text-color);
  /*font-weight: 900;*/
}

/* STATS CARDS */

/* 
.lykiocss-home .lykiocss-home-stats-wrapper > .lykiocss-home-stats {
  align-self: center;
}
*/
.lykiocss-home .lykiocss-home-stats-wrapper > .lykiocss-home-stats .MuiCard-root.MuiPaper-root .MuiCardContent-root .lykiocss-card-content .progress .progress-bar {
  background-color: var(--primary-color);
}

.lykiocss-card-title, .lykiocss-card-content {
  /*background-color: var(--primary-color);*/
  padding: 10px;
  padding-bottom: 0px;
  padding-top: 5px;
}

.lykiocss-card-title p.MuiTypography-root, .lykiocss-card-content p.MuiTypography-root  {
  font-weight: 900;
  font-size: 24px;
}

/* Platform Statistics */

.lykiocss-home .lykiocss-home-stats-wrapper{
  margin-top:3em;
}

.lykiocss-home .lykiocss-home-stats-wrapper > .lykiocss-home-stats > .MuiCard-root.MuiPaper-root {
  background-color: rgb(132, 117, 78,0.3);
  border-radius: var(--border-radius);
  height: 180px;
  max-width: 315px;
}
.lykiocss-home .lykiocss-home-stats-wrapper > .lykiocss-home-stats > .MuiCard-root.MuiPaper-root > div.MuiCardContent-root > div > p.MuiTypography-root > div > span {
  padding: 3% 3% 3% 0%;
  display: inline-block;
}

.lykiocss-home .lykiocss-home-stats-wrapper > .lykiocss-home-stats.lykiocss-home-stats1 .profile-box-bottom,.lykiocss-home .lykiocss-home-stats-wrapper > .lykiocss-home-stats.lykiocss-home-stats2 .profile-box-middle, .lykiocss-home .lykiocss-home-stats-wrapper > .lykiocss-home-stats.lykiocss-home-stats2 .profile-box-top{
  display:none;
}

.lykiocss-home .lykiocss-home-stats-wrapper > .lykiocss-home-stats.lykiocss-home-stats1 .profile-box-middle > div {
  margin-bottom: 2em;
}

/*.lykiocss-home .lykiocss-home-stats-wrapper > .lykiocss-home-stats > .MuiCard-root.MuiPaper-root > div.MuiCardContent-root > p.MuiTypography-root > div > span > svg {*/
.lykiocss-home .MuiPaper-root span > svg  {
  margin: 0.3em;
}

/* Buttons */
.lykiocss-home div .row div .splide__arrow svg {
	fill: var(--secondary-color);
}

.lykiocss-home-stats button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButtonBase-root.css-7nbtlc-MuiButtonBase-root-MuiButton-root {
  width: 36%;
  /*margin-left: 10px;*/
} 
.MuiCardContent-root, .MuiCardActions-root {
  padding: 20px 0px 0px 0px!important;
}
.lykiocss-home-stats .css-12jau5h-MuiPaper-root-MuiCard-root {
  max-width: 100%!important;
}
