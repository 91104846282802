.lykiocss-story-item {
  position: relative;
  flex: 1 0 12rem;
  margin: 0rem;
  color: #fff;
  cursor: pointer;
}

.lykiocss-story-item:hover .lykiocss-story-item-info,
.lykiocss-story-item:focus .lykiocss-story-item-info {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: var(--border-radius);
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.5s ease;
}

.lykiocss-story-item-info {
  display: none;
  transition: 0.5s ease;
}

.lykiocss-story-item-visible-info {
  position: absolute;
  border-radius: var(--border-radius);
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
}

.lykiocss-story-item:hover .lykiocss-story-item-visible-info,
.lykiocss-story-item:focus .lykiocss-story-item-visible-info {
  display: none;
}

.lykiocss-story-item-title{
  font-size: 1.5rem;
  font-weight: 600;
  text-align : center;
  text-shadow: 1px 1px black;
  width:95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lykiocss-text-shadow{
  text-shadow: 1px 1px black;
}

.lykiocss-story-description{
  font-size: 1.2rem;
  font-weight: 500;
  text-align : center;
}

.lykiocss-story-item-info li {
  font-size: 1.7rem;
  font-weight: 600;
}

.lykiocss-story-item-type {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2.5rem;
  text-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1);
}

.lykiocss-story-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: black;
  border-radius: var(--border-radius);
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.lykiocss-story-white-icon{
  color: white !important;
  margin-right: 0.7em;
}

.lykiocss-story-goto{
  margin-top: 1em;
  font-size: large;
}

.lykiocss-top-overlay {
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: 2.5s ease;
}

.lykiocss-story-description{
  font-weight: 300;
}