.lykiocss-profile-container {
    height: 100vh
}

.lykiocss-profile-card {
    width: 30em;
    border: none;
    border-radius: var(--border-radius);
    padding: 0.5em;
    background-color: #fff;
    position: relative;
    height: 25em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4em;
}


.lykiocss-profile-upper {
    height: 8em;
    color: grey;
    background-color: grey;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.lykiocss-profile-upper img {
    width: 100%;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.lykiocss-profile-user {
    position: relative
}

.lykiocss-profile-profile img {
    height: 6em;
    width: 6em;
}

.lykiocss-profile-profile {
    position: absolute;
    top: -50px;
    left: 38%;
    height: 6em;
    width: 6em;
    border-radius: 50%
}

.lykiocss-profile-follow {
    border-radius: var(--border-radius);
    padding-left: 1em;
    padding-right: 1em;
    height: 2em;
}

.lykiocss-profile-stats span {
    font-size: 2em;
}

.lykiocss-profile-button{
    width: auto;
}

.lykiocss-profile-text-color{
    color: var(--primary-text-color);
}