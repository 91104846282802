.lykiocss-content-creation-dashboard-content {
    overflow: auto;
    height: 100%;
    padding-bottom: 10vh;
}

.lykiocss-content-creation-dashboard-title {
    font-size: 2em;
    font-weight: 800;
    /*margin-bottom: 1em;*/
    color: var(--primary-color);
    text-transform: uppercase;
}

.lykiocss-content-creation-dashboard-user-guide-button-container {
    margin-top: 1rem!important;
    margin-bottom: 2rem;
}

.lykiocss-content-creation-dashboard-place-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lykiocss-content-creation-dashboard-place-items div {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: inline;
}

.lykiocss-content-creation-dashboard-button {
    width: 13rem;
}

.lykiocss-content-creation-dashboard-middle-text {
    margin-top: 2rem!important;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 1.5rem;
}

.lykiocss-content-creation-dashboard-container {
    max-width: 93.5rem;
    margin-bottom: 1rem;
    /*margin: 0 auto;*/
    /*padding: 0 2rem;*/
}

.lykiocss-content-creation-dashboard-gallery {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: repeat(3,1fr);
    padding: 0rem 4rem 4rem 4rem;
}

.lykiocss-content-creation-dashboard-card {
    background-color: var(--form-background-color);
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)!important;
}

.lykiocss-content-creation-dashboard-card-text {
    color: var(--primary-color)!important;
    padding: 0.3rem;
    text-align: center;
}

@media screen and (min-width: 769px) and (max-width: 991px) {
    .lykiocss-content-creation-dashboard-gallery {
        grid-gap: 3.5rem;
        grid-template-columns: repeat(2,1fr);
    }
}

@media screen and (max-width: 768px) {
    .lykiocss-content-creation-dashboard-gallery {
        grid-template-columns: repeat(1,1fr);
        padding: 0;
    }
    .lykiocss-content-creation-dashboard-card {
        width: 22rem;
        margin: 0 auto;
    }
}