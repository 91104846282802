.lykiocss-active-profile-image {
    max-width: 2rem!important;
    height: 2rem!important;
    display: inline-block;
}

.lykiocss-active-profile-container {  
    display: flex;
    justify-content: start;
    align-items: end;
  }

.lykiocss-profile-card-container {
  position: relative;
  border-radius: var(--border-radius);
}

.lykiocss-profile-card-image {
  display: block;
  width: 100%;
  border-radius: var(--border-radius);
  height: auto;
}

.lykiocss-profile-card-overlay {
  position: absolute; 
  bottom: 0; 
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1; 
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.lykiocss-profile-card-container:hover .lykiocss-profile-card-overlay {
  opacity: 1;
}

.lykiocss-active-profile-name{
  font-size: medium;
  font-weight: 400;
}

.lykiocss-active-profile-with-name > a:link{
  text-decoration: none;
}