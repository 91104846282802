.lykiocss-unitModal-container {
  display: flex;
}

.lykiocss-unitModal-media {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.lykiocss-unitModal-story-uploader-name {
  font-weight: bold;
  font-size: 18px;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  position: relative;
}

.lykiocss-unitModal-story-uploader-date {
  font-weight: normal;
  font-size: 14px;
  bottom: 0;
  position: absolute;
  right: 0;
  padding-bottom: 0.3rem;
}

.lykiocss-unitModal-story-text-video {
  max-height: 13rem;
  overflow: auto;
  font-size: 14px;
}

.lykiocss-unitModal-story-text-image {
  max-height: 18rem;
  overflow: auto;
  font-size: 14px;
}

.lykiocss-unitModal-comments-rating {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media (max-width: 992px) {
  .lykiocss-unitModal-container {
    display: block;
  }
}