/*
.lykiocss-library-grid-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 110em;
  justify-content: center;
}

.lykiocss-library-title {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.lykiocss-library-description{
  margin-top: 2em;
  margin-bottom: 5em;
}

.lykiocss-library-form-border{
  padding: 1rem;
  border: .2rem solid #ececec;
  border-radius: var(--border-radius);
  margin-left: 1em;
  margin-right: 1em;
}
*/

.profile-user-box {
  background-color: var(--primary-color)!important;
  color: var(--secondary-color-accent)!important;
  margin-left: 7em;
  margin-right: 7em;
  margin-top: 1em;
  position: relative;
  border-radius: var(--border-radius);
}

.card-box {
  padding: 20px;
  border-radius: var(--border-radius);
  margin-bottom: 30px;
  background-color: #fff;
}

.font-13 {
  font-size: 13px!important;
}


/* .lykiocss-library-category-header{
  min-height: 150px;
  width: 100%;
  min-width: 35em!important;
  padding-top: 30px;
  text-align: center;
  line-height: 30px;
  height: 100px;
  color: white;
  font-size: x-large;
  background: url("./../../multimedia/education_header.png") no-repeat center top scroll;
  border-radius: var(--border-radius);
  overflow-wrap: break-word;
} */


.lykioccs-category-library .lykiocss-library-category-header h1 {
  font-size: 3em;
  font-weight: 900;
}
.lykioccs-category-library .lykiocss-library-category-header p {
  font-size: 18px;
  background-color: var(--primary-color);
  padding: 20px;
  border-radius: var(--border-radius);
}

.lykioccs-category-library button.MuiButton-root{
  padding: 0.5em;
  text-transform: none;
  border-radius: 5px;
  border: 1px solid #A3915F;
  margin: 1em 1em 1em 0em;
  font-weight: 700;
}

.lykioccs-category-library .splide__pagination{
  bottom: -1.5em;
}

.lykioccs-category-library > .container {
  margin-left: 1em !important;
}

.lykiocss-subcategory-modal-title{
  font-size: 25px;
  font-weight: 900;
  margin-bottom: 0.5em;
  color: var(--primary-color);
}