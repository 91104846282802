.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid transparent;
  border-radius: 0;
}
.btn-circle.btn-lg, .btn-group-lg>.btn-circle.btn {
  width: 50px;
  height: 50px;
  padding: 14px 15px;
  font-size: 18px;
  line-height: 23px;
}
.text-muted {
  color: #8898aa!important;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}
.btn-circle {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 10px;
}
.user-table tbody tr .category-select {
  max-width: 150px;
  border-radius: var(--border-radius);
}

.lykiocss-table-height{
  max-height: 15em;
}

.lykiocss-leaderboards-scrollable-table{
  height: 250px;
  overflow: scroll;
}

.lykiocss-leaderboards-no-border{
  border-style: none;
}

.lykiocss-table-borderless > tbody > tr > td,
.lykiocss-table-borderless > tbody > tr > th,
.lykiocss-table-borderless > tfoot > tr > td,
.lykiocss-table-borderless > tfoot > tr > th,
.lykiocss-table-borderless > thead > tr > td,
.lykiocss-table-borderless > thead > tr > th {
    border: none;
}

.lykiocss-leaderboard-score{
  font-weight: 600;
}