.lykiocss-stories .container {
  margin-top: 0em;
}

h2.lykio-stories-feature-stroy-title{
  font-size: 4em;
  font-weight: 900;
  text-align: center;
}

.lykiocss-stories-title h1{
  font-size:5em;
  font-weight:900;
  margin-bottom:0;
}

.lykiocss-stories-tagline{
  font-size: 2em;
  font-weight: 800;
  margin-bottom:1em;
  color: var(--primary-color);
  text-transform: uppercase;  
}

.lykiocss-stories-grid-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 110em;
  justify-content: center;
}

.lykiocss-stories-title {
  display: flex;
  justify-content: center;
}

.lykiocss-stories-tenant-name{
  font-size:7.5em;
  font-weight:900;
  margin-bottom:0;
}

.lykiocss-stories-stories-name{
  font-size:4.5em;
  font-weight: 800;
  margin-left: 30px;
  margin-top: 50px;
}

.lykiocss-stories-container {
  max-width: 93.5rem;
  margin: 0 auto;
  padding: 0 2rem;
}

.lykiocss-stories-gallery {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  padding-bottom: 1rem;
}

.lykiocss-stories-loader-center{
  display: flex;
  justify-content: center;
}


/* BOOTSTRAP CAROUSEL*/

/* .lykiocss-stories .carousel.slide {
  border-radius: 0px;
} */
.lykiocss-stories .carousel.slide .carousel-item {
  height: 25vh;
}
.lykiocss-stories .carousel.slide .carousel-item > img {
  opacity: 0.5;
  transition: 0.3s;
  height: 24em;
  object-fit: cover;
}
.lykiocss-stories .carousel.slide .carousel-item > img:hover {
  opacity: 1;
}

.lykiocss-stories .carousel.slide .carousel-item .MuiCard-root.MuiPaper-root{
  position: absolute;
  left: calc(40% - 190px);
  top: -25px;
}
.lykiocss-stories .carousel.slide .carousel-item .MuiCard-root.MuiPaper-root .MuiCardActions-root button:nth-of-type(2) {
  display:none;
}



.lykiocss-stories .carousel.slide .carousel-item .MuiCard-root.MuiPaper-root .MuiTypography-root{
  font-weight: 600;
  font-size: 3vmin;
  letter-spacing: 5px;
}

.lykiocss-stories .carousel.slide .carousel-control-next, .lykiocss-stories .carousel.slide .carousel-control-prev{
  width: 4%;
  /* align-items: end; */
}

.lykiocss-stories .carousel.slide .carousel-indicators [data-bs-target]{
  border-top: 4px solid white;
  border-bottom: 1px solid white;
  border-radius: var(--border-radius);
  width: 10px;
}


@media screen and (min-width: 901px) {
  .lykiocss-stories-gallery {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .lykiocss-stories-gallery {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 0px) and (max-width: 600px) {
  .lykiocss-stories-gallery {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(1, 1fr);
    padding-bottom: 1rem;
  }

  .lykiocss-stories-tenant-name{
    display: none;
  }
}