.create-story-screen {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lykiocss-create-form-color {
  background: #fff;
}

.create-story-screen__title {
  text-align: center;
  margin-bottom: 1rem;
}

.create-story-screen__subtext {
  font-size: 1rem;
  display: block;
  margin: 0.5rem 0;
}

div.codex-editor {
  border: 0.1rem solid #ced4da;
  border-radius: var(--border-radius);
  min-height: 20vh;
  max-height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.lykiocss-create-background{
  background: linear-gradient(0deg, rgb(0, 0, 0) 0, rgb(0, 0, 0), rgba(0, 0, 0, 0.2)), url("./../../multimedia/viceroy-upload-background.jpg") no-repeat center top scroll
}


.lykiocss-create-file-showcase{
  min-height: 100px;
  max-width: 300px;
  margin: auto;
}

.lykiocss-create-file-showcase-message{
  max-width: 300px;
  margin-left: 1em; 
}

.lykiocss-create-button-bottom-right{
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.lykiocss-reset-button-top-right{
  position: relative;
  right: 10px;
  top: 10px;
}