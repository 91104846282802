.profile-box-container {  
    display: grid;
	grid-template-columns: 15em;
	grid-template-rows: 1.5em 3.5em 3.5em;
	gap: 0.5em 0;
	grid-auto-flow: row;
	grid-template-areas:
	  "."
	  "."
	  ".";
}
/* 
.profile-box-top { grid-area: 1 / 1 / 2 / 2; }
.profile-box-middle { grid-area: 2 / 1 / 3 / 2; }
.profile-box-bottom { grid-area: 3 / 1 / 4 / 2; } 
*/
.profile-box-top {
    display: flex;
}
.profile-box-top > div {
    width: 70%;
}
.profile-box-top .lykiocss-profile-box-top-level{ 
    display: flex;
    width: 9%;
    align-self: center;
    justify-content: end;
}
.profile-box-middle {
    max-width: 284px;
}
.profile-box-middle > div {
    margin-bottom: 1em;
}

.lykiocss-navigation-badge{
	display: inline-block;
	width: 2.3em;
    margin-right: 0.2em;
}

.profile-box-top > .lykiocss-profile-box-top-level > span.badge.bg-primary{
    background-color: var(--primary-color)!important;
}

.progress-bar.bg-progress-bar-custom{
    background-color: var(--primary-color);
    font-weight: 300;
    padding: 3px;
    text-shadow: 1px 1px black !important;
}

.lykiocss-profilebox-text-style{
    font-size: medium;
}

.lykiocss-profile-box-achievements {
    display: grid;
    grid-template-columns: 13em;
}