.meta-navbar-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

.lykiocss-meta-anchor{
  color: var(--primary-color-accent);
  text-decoration: none !important;
  text-transform: uppercase;
}

.lykiocss-meta-anchor:hover {
  filter: brightness(1.2);
  color: var(--primary-color);
  cursor: pointer;
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.5);     
}

.meta-navbar {
  height: var(--nav-size);
  background-color: var(--primary-color);
  padding: 0 0;
  display: block!important;
}

/* <ul> */
.meta-navbar-nav {
  max-width: 100%;
  height: 100%;
  padding-left: 1rem;
  display: flex;
}

/* <li> */
.meta-nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.meta-styled-anchor{
  color:var(--secondary-color);
  text-decoration: none  !important;
}

.meta-nav-center-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Icon Button */
.meta-icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: var(--primary-color);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: filter 300ms;
  color:var(--secondary-color);
}

.meta-icon-button:hover {
  filter: brightness(1.2);
  color: var(--secondary-color);
}

.meta-center-icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: var(--primary-color);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: filter 300ms;
}

.meta-center-icon-button:hover {
  filter: brightness(1.2);
  color: var(--secondary-color);
}

.meta-logo{
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.meta-logo:hover {
  filter: brightness(1.2);
}

.meta-icon-button svg { 
  fill: var(--secondary-color);
  width: 20px;
  height: 20px;
}

.meta-icon-button {
  margin-right: 0.5rem;
}


.meta-icon-button:hover {
  filter: none;
}

.meta-auto-left-margin{
  margin-left: auto;
}

.lykioccs-navigation-navbar-logo-image {
  width: 5rem;
}

@media screen and (min-width: 901px) {
  .lykioccs-navigation-navbar-logo-image{
    width:5rem;
  }
  .meta-center-icon-button{
    display: none;
  }
  .meta-center-text-button{
    color:var(--secondary-color) !important;
    font-weight: 400!important;
  }
  .meta-navigation-options-profile{
    display:none
  }
  .meta-nav-center-item {
    width: auto;
    margin-right: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 481px) and (max-width: 900px) {
  .lykioccs-navigation-navbar-logo-image{
    width:4rem;
  }
  .meta-center-text-button{
    display:none !important;
  }
  .meta-navbar-nav {
    max-width: 100%;
    height: 100%;
    padding-left: 0.2rem;
    display: flex;
  }
  .meta-navigation-profile-icon{
    display:none;
  }
  .meta-navigation-options-profile{
    display:inline;
  }
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .lykioccs-navigation-navbar-logo-image{
    width:3rem;
  }
  .meta-center-text-button{
    display:none !important;
  }
  .meta-navbar-nav {
    max-width: 100%;
    height: 100%;
    padding-left: 0.2rem;
    display: flex;
  }
  .meta-navigation-profile-icon{
    display:none;
  }
  .meta-navigation-options-profile{
    display:inline;
  }
}

