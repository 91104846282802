.lykiocss-login-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .lykiocss-login-screen-form {
    width: 380px;
    padding: 1.5rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    background: #fff;
  }
  
  .lykiocss-login-screen-title {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .lykiocss-login-screen-forgotpassword {
    font-size: 0.7rem;
  }
  
  .lykiocss-login-screen-subtext {
    font-size: 1rem;
    display: block;
    margin: 0.5rem 0;
  }

  .lykiocss-login-screen-extra-options{
    margin-top: 3.5em;
  }