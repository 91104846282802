.lykiocss-firstadminscreen-screen {
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .lykiocss-firstadminscreen-screen-form {
    padding: 1.5rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    background: #fff;
  }
  
  .lykiocss-firstadminscreen-screen-title {
    text-align: center;
    margin-bottom: 1rem;
  }