.lykiocss-units-div {
	padding-bottom: 1em;	
}

.lykiocss-units-div .lykiocss-splide-div{
	color:var(--primary-text-color);	
}

.lykiocss-units-title {
	font-weight: 900;
    font-size: 2rem;
    min-height: 2em;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

/* Changes from other components */
.lykiocss-unit {
	margin: 0 !important;
}
