.lykiocss-leaderboards-container{
    padding-top:2.5em;
    padding-left: 0em !important;
    padding-right: 0em !important;
}

.lykiocss-leaderboards-container-header{
    background-color:var(--primary-color);
    color: var(--secondary-color);
    margin-bottom: 0em;
}

.lykiocss-leaderboards-container > .row > .col > .card {
    border-radius: 0px;
} 

.lykiocss-leaderboards-no-radius{
    border-radius: 0px !important;
}

.lykiocss-leaderboards-container > .row > .col > div > .container{
    padding-left: 0em;
    padding-right: 0em;
}
.lykiocss-leaderboards-container .adjustLeaderboardTitle{
    font-size: 1.35em;
    padding: 0.5em;
}

/* .lykiocss-leaderboards-no-padding{
    padding-left: 0em;
    padding-right: 0em;
} */