@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  -ms-overflow-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color:  #484a4d;
  --primary-color-accent: #484a4d;
  --secondary-color: white;
  --secondary-color-accent: #fbfcfd;
  
  --background-color: white;
  --form-background-color: white;
  --active-color: #1976d2;
  
  --primary-text-color: black;
  --reversed-text-color: #fbfcfd;
  --special-text-color: black;
  
  --muted-text-color: #484a4d;
  --nav-size: 70px;
  --border: 1px solid #474a4d;
  --border-radius: 5px;
  --speed: 500ms; 

  --background-gradient: linear-gradient(0deg,rgb(0, 0, 0) 0%,rgb(0, 0, 0) 50%,rgba(0, 0, 0, 0.2) 100%);
  --background-home-url: none;
  --background-category-url: none;
  --home-background-url: none;
  --background-stories-url: none;
  --background-generic-url: none;
  --background-upload-url: none;
  --generic-logo-url :url('./multimedia/Default/white_loading.gif');
  /* --generic-logo-url :url('./multimedia/Default/lykio_logo_transparent.png'); */
  --background-login-url: url('');
  /* --background-login-url: url('./multimedia/Default/login_bg.jpg'); */
}

.lykiocss-home {
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  color: var(--reversed-text-color);
}

.tt-background{
  background-image: var(--home-background-url);
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
}

.lykiocss-category-bg {
  background-image: var(--background-category-url);
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  color: var(--reversed-text-color);
}

.lykiocss-stories {
  background-image: var(--background-stories-url);
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  color: var(--reversed-text-color);
}

.lykiocss-generic-bg {
  background-image: var(--background-generic-url);
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  color: var(--reversed-text-color);
}

.lykiocss-upload-bg {
  background-image: var(--background-upload-url);
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  color: var(--reversed-text-color);
}

.lykiocss-forms-container {
  display: grid;
  height: 100%;
  overflow: auto;
}

.lykiocss-generic-background{
  background-repeat: no-repeat;
  background-size: cover;
  background-image: var(--background-login-url);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 300;
  line-height: 1.7;
  background-color: var(--background-color) !important;
  color: var(--primary-text-color);
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

p{
  font-weight: 300;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
/* ScrollBar */
::-webkit-scrollbar {
  width: 8px;
}
 
::-webkit-scrollbar-thumb {
  background: rgb(206, 202, 202); 
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(206, 202, 202); 
}

.lykioccs-main-content {
  overflow: auto;
  height: 100%;
  padding-bottom:10vh;
}

#root,.app {
  height: 100%;
}

.form-group,
.form-group label,
.form-group input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-group label {
  font-size: 0.8rem;
}

.form-group input {
  padding: 10px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #eee;
  outline-width: 0;
  font-size: 1rem;
}

.form-group input:focus {
  border-bottom: 3px solid lightslategray;
}

.btn {
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  border: none;
}

.btn:hover {
  opacity: 0.8;
}

.btn-primary {
  background-color: lightslategray;
  color: #fff;
}

.btn-secondary {
  background-color: #7e7d7b;
  color: #fff;
}

.lykiocss-login-logo{
  padding: 10px 20px;
  width: 20em;
  font-size: 1rem;
  border: none;
}

.lykiocss-small-login-logo{
  font-size: 1rem;
  width: 10em;
  border: none;
}

.lykiocss-form{
  background-color: var(--form-background-color);
  color: var(--primary-text-color);
  border-radius: var(--border-radius);
}

.lykiocss-form-border{
  padding: 1.5rem 2rem;
  border: 0.01rem solid #d6d6d6;
  border-radius: var(--border-radius);
  margin: 1em;
}

.lykiocss-login-form-border{
  padding: 3rem 2rem;
  border: .2rem solid #ececec;
  border-radius: var(--border-radius);
  margin: 1em;
}

.lykiocss-form-size{
  min-width: 28rem;
}

.lykiocss-form-button{
  width: 10em;
  margin-left:auto;
  margin-right:auto;
}

.lykiocss-uppercase{
  text-transform: uppercase;
}

.lykiocss-center{    
  display: flex;
  justify-content: center;
  align-items: center;
}

.lykiocss-10em{
  width: 10em;
}

.lykiocss-screen-center{
  display: flex;
  justify-content: center;
  align-items: center;
  /*height: 100vh;*/
}

.lykiocss-screen-center-80vh{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.lykiocss-white-background{
  background-color:white;
}

.lykiocss-max-text-ellipsis{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lykiocss-grapes-dark-background{
  background-color: #444 !important;
}


@media (max-width: 768px) {
  .lykiocss-form-size{
    width: auto;
    /*min-width: unset;*/
    min-width: 20rem; 
  }
  .lykiocss-login-logo{
    width: 17em;
  }
}

.lykiocss-text-muted {
  color: #8898aa!important;
}

.lykiocss-stick-to-bottom{
  position: fixed; 
  bottom:0%;
  margin-left: auto;
  margin-right: auto;
}

.lykiocss-unstyled-link{
  text-decoration:none;
}

.lykiocss-centered{
  display: flex;
  justify-content: center;
}

.lykiocss-all-rounded{
  border-radius: var(--border-radius);
}

.lykiocss-active-on-hover{
  filter: brightness(1.2);
  color: var(--active-color);
  cursor: pointer;
}

.primary.dropdown-toggle {
  background-color: var(--secondary-color); 
  color: var(--primary-color);
}

.btn-primary{
  background-color: var(--secondary-color) !important; 
  border-color: var(--primary-color) !important; 
  color: var(--primary-color) !important;
}

.lykiocss-scrollable-table-400{
  height: 400px;
  overflow: scroll;
}

.lykiocss-scrollable-table-450{
  height: 450px;
  overflow: scroll;
}

.lykiocss-2em-font-size{
  font-size: 2em;
}

.lykiocss-no-padding{
  padding: 0 !important;
}

.lykiocss-margin-top-half-em{
  margin-top: 1em !important;
}

.lykiocss-margin-top-1em{
  margin-top: 1em !important;
}

.lykiocss-margin-top-2em{
  margin-top: 2em !important;
}

.lykiocss-margin-top-4em{
  margin-top: 4em !important;
}

.lykiocss-margin-bottom-half-em{
  margin-bottom: 0.5em;
}

.lykiocss-material-table > table > tbody > tr > td {
  padding: 5px 0px;
}

.lykiocss-material-table{
  padding: 3px;
  border-radius: var(--border-radius);
  background-color: var(--form-background-color);
  margin-top: 5px;
  margin-left: 5px;
}

.lykiocss-active-hover:hover , .lykiocss-active-hover:focus {
  color: var(--active-color);
  cursor: pointer;
}

.lykiocss-contrast-text{
  color: var(--reversed-text-color);
}

.lykiocss-title-light{
  font-size: 24px;
  font-weight: 300;
}

.lykiocss-subtitle-light{
  font-size: 20px;
  font-weight: 300;
}

.lykiocss-text-showcase-light{
  font-size: 18px;
  font-weight: 200;
}

.lykiocss-scrollable-table{
  max-height: 250px;
  overflow: scroll;
}

.lykiocss-transparent-image-border_{
  -webkit-filter: drop-shadow(0.01px 0.01px 0 var(--secondary-color-accent))
  drop-shadow(-0.01px -0.01px 0 var(--secondary-color-accent));
  filter: drop-shadow(0.01px 0.01px 0 var(--secondary-color-accent)) 
  drop-shadow(-0.01px -0.01px 0 var(--secondary-color-accent));
}

.lykiocss-playlist-item{
  font-size: 'large'; 
  margin-bottom: '0.5em';
}

.lykiocss-activegroup-link:link{
  text-decoration: none;
}


/* .lykiocss-home-stats1 {
  max-width: 35%;
} */

.lykiocss-featured-story-background{
  opacity: 0.75;
}

.lykiocss-featured-story-title{
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 0.5em;
  width:95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lykiocss-featured-story-uploader{
  font-weight: 500;
  margin-bottom: 1.5em;
}

.lykiocss-featured-story-bg-image {
  /* Add the blur effect */
  filter: blur(2px);
  -webkit-filter: blur(2px);
  
  /* Full height */
  height: 100%; 
  
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px
}

.lykiocss-featured-story-bg-text {
  /* background-color: rgb(0,0,0); /* Fallback color */
   /*background-color: rgba(0,0,0, 0.4); Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  /* position: absolute;
  top: 50%;
  left: 50%; */
  transform: translate(0%, -100%);
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;
  border-radius: 8px
}

.lykiocss-featured-story-buttons{
  position:absolute;
  width:100%;
  right: 5px;
  bottom:20px;
}

.lykiocss-text-shadow{
  text-shadow: 0.1px 0.1px grey !important;
}

.lykiocss-guides-iframe > iframe{
  position: relative; 
  height: 40em!important; 
  width: 100%!important;
}

.lykiocss-login-light{
  font-size:13px;
  max-width: 20em;
  line-height: 15px;
  font-weight: 300;
  text-align: center
}

.lykiocss-dark-color{
  color:black!important;
}

.lykiocss-form-bottom-padding{
  padding-bottom: 3.5rem;
}

.lykiocss-hide{
  display: none;
}

.enabledSchoolsBackground > .cs-message-list > .scrollbar-container{
  background-image: url('./multimedia/textureTT.png');
  background-repeat: repeat;
}

.enabledSchoolsBackground > .cs-message-list > .scrollbar-container{
  background-image: url('./multimedia/textureTT.png');
  background-repeat: repeat;
}

.tt-access-code-button{
    border-style: groove;
    padding: 0.35em;
    border-radius: 5px;
}

.lykio-body{
  position:relative;
}
.lykio-support-footer {
  position:absolute;
  color: gray;
  font-size: small;
  bottom:0;
  right: 10px;
}