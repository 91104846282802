.lykiocss-starrating-container  {
    display: flex;
}

.lykiocss-starrating-button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.lykiocss-starrating-buttonoff {
    cursor: default;
}