.lykiocss-comments-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.lykiocss-comments-heading {
    border-bottom: 1px solid #efefef;
    font-size: 14px;
    padding:14px 16px;
}

.lykiocss-comments-area {
    height: 100%!important; 
    overflow: auto;
}

.lykiocss-comments-middle {
    height:0;
    padding-bottom: 100%;
}

.lykiocss-comments-container {
    font-size: 14px;
    line-height: 18px;
    padding: 16px;
}

.lykiocss-comments-singlecomment {
    display:flex;
    margin-bottom: 16px;
    padding-left: 0px;
}

.lykiocss-comments-singlecomment:last-child {
    margin-bottom: 0px;
}

.lykiocss-comments-div {
    margin-right: 1.2rem;
    width:5%;
}

.lykiocss-comments-image {
    width: 2rem;
    height: 2rem;
}

.lykiocss-comments-uploader-info {
    display: flex;
}

.lykiocss-comments-uploader-container {
    position: relative;
    width: 100%;
}

.lykiocss-comments-uploader-name {
    position: absolute;
    font-weight: 400;
    margin-top: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 95%;
}

.lykiocss-comments-details {
    margin-top: 0.5rem;
}

.lykiocss-comments-commentername {
    margin-right: 4px;
    font-weight: 500;
}

.lykiocss-comments-commenttext {
    overflow-wrap: anywhere;
}

.lykiocss-comments-commentdate {
    display: block;
    margin-top: 8px;
    color:#8e8e8e;
}

.lykiocss-comments-commentbox {
    margin-top:auto;
    border-top: 1px solid #efefef;
    padding: 6px 16px;
}

.lykiocss-comments-input-emoji {
    display: flex;
    position: relative;
    align-items: center;
}

.lykiocss-comments-textareainside {
    font-size: 14px;
    border:0;
    color:black;
    width: 80%;
    height: 18px;
    max-height: 36px;
    line-height: 18px;
    outline: 0;
    padding: 0;
    resize: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 9%;

}

.lykiocss-comments-postbuttondiv {
    opacity: 0.3;
    margin-left: auto;
    width:10%;
}

.lykiocss-comments-postbutton {
    background: none;
    border: none;
    color:#0095f6;
    font-size: 14px;
    margin: 0;
    padding: 0;
}

.lykiocss-comments-rating {
    display: flex;
    border-top: 1px solid #efefef;
    padding: 6px 16px;
    font-size: 24px;
}

.lykiocss-comments-bookmarkbutton {
    margin-left: auto;
}

.react-input-emoji--input {
    max-height: 85px!important;
    overflow-wrap: anywhere!important;
}

@media (max-width: 768px) {
    .lykiocss-comments-div {
        margin-right: 1.4rem;
    }   
} 

@media screen and (min-width: 0px) and (max-width: 991px) {
    .lykiocss-comments-area{
        display: none;
    }
}
