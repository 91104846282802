.lykiocss-unitoverview-allcontent {
    width:60%;
    border-radius: var(--border-radius);
    background-color: white;
    margin:0 auto;
    margin-top: 0.5em;
}

.lykiocss-unitoverview-container {
    display: flex;
    margin:0 auto;

    flex-direction: row;
    width:100%;
}

.lykiocss-unitoverview-leftpart {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.lykiocss-unitoverview-infopart {
    margin-bottom: 2%;
}

.lykiocss-unitoverview-image {
    height:100%;
    width: 100%;
}

.lykiocss-unitoverview-rightpart {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: auto;
}

.lykiocss-unitoverview-unit-title {
    font-size: 26px;
    font-weight: 300;
    padding-left: 5px;
    padding-top: 0.2em;
    padding-bottom: 0.3em;
    color: #000;
    margin-bottom: 0px;
}

.lykiocss-unitoverview-description {
    cursor: pointer;
    margin-left: 5px;
	margin-top: 5px;
    font-weight: 300;
	font-size: 14px;
	color: #000;
    max-height: 8rem;
    overflow: auto;
}

.lykiocss-unitoverview-unit-info {
    display: flex;
    align-items: center;
    margin-bottom: 0.2em;
    margin-left: 0.5em;
}

.lykiocss-unitoverview-container-text {
    padding: 0;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 300;
    color: #000;
}

.lykiocss-unitoverview-container-text i {
    cursor: default!important;
}

.lykiocss-unitoverview-lessonbutton {
    width: 20%;
    margin-left: auto;
}

@media (max-width: 1200px) {
    .lykiocss-unitoverview-allcontent {
        width:90%;
    }
}

@media (max-width: 768px) {
    .lykiocss-unitoverview-container {
        display: block;
    }
}

