.lykiocss-add-category- {
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.lykiocss-add-category-form {
    width: 380px;
    padding: 1.5rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    background: #fff;
}
  
.lykiocss-add-category-title {
    text-align: center;
    margin-bottom: 1rem;
}

.lykiocss-form-button {
    width: 15em;
}    