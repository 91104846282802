.lykioccs-notificationslist-hover{
    cursor: pointer;
    color: blue;
    text-decoration: none;
}

.lykiocss-notificationlist-sender{
   padding-left: 0px !important;
   padding-right: 10px !important;
}

.lykiocss-notification-accordion-body > .accordion-collapse > .accordion-body {
    padding-bottom: 2rem!important;
}

.lykiocss-notification-date{
    font-weight: 200;
    font-size: small;
    /*margin-left: 1em;*/
    /*margin-right: 0.3em;*/
}

.lykiocss-notification-title{
    font-weight: 300;
    margin-right: 0.5em;
}

.lykiocss-notification-body-date{
    margin-right: 0.3em;
    font-weight: 200;
    font-size: small;
}