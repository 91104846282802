.dropdown, .dropend, .dropstart, .dropup {
  display: inline-block;
}

.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}
.Lykiocss_table_bg{
  margin-top: 1rem;
  background-color: #e3e3e3;
}