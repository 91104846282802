.lykiocss-profile-awards-title {
    text-align: center;
    margin-top: 1rem;
}

.lykiocss-profile-awards-container {  
    text-align: center;
    padding: 0!important;
    margin: 0!important;
}

.lykiocss-profile-awards-break-line { 
    display: block;
    height: 0;
}

.lykiocss-profile-awards-singe-award {
    display: inline-block;
    margin-left: 1rem; 
    margin-right: 1rem;
    margin-bottom: 2rem;
    font-size: 1rem;
    min-width: 27%;
}

.lykiocss-profile-awards-image-area {
    position: relative;
}

.lykiocss-profile-awards-image { 
    width: 9rem;
    height: 9rem;
    display: inline;
}

.lykiocss-profile-awards-lock-div { 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.lykiocss-profile-awards-lock-icon { 
    font-size: 3rem;
    cursor: default;
    color: black!important;
}

.lykiocss-profile-awards-text-area {
    margin-top: 0.5rem;
}

.lykiocss-profile-awards-singe-award-title {
    font-weight: bold;
}

@media (max-width: 768px) {
    .lykiocss-profile-awards-image {
        width: 7rem;
        height: 7rem;
    }

    .lykiocss-profile-awards-break-line { 
        display: none;
    }

    .lykiocss-profile-awards-singe-award {
        font-size: 0.8rem;
    }

    .lykiocss-profile-awards-lock-icon { 
        font-size: 2rem;
    }
}