.lykiocss-featuredInfoBox-Item {
  flex: 1;
  margin: 0px 0px;
  padding: 30px;
  background-color:var(--secondary-color);
  opacity: 1;
  width: auto;
  max-width: 15em;
  border-radius: var(--border-radius);
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.lykiocss-featuredInfoBox-styled-anchor{
    color:var( --primary-text-color);
    text-decoration: none  !important;
}

.lykiocss-featuredInfoBox-Title{
    font-size: 20px;
}

.lykiocss-featuredInfoBox-FigureContainer{
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.lykiocss-featuredInfoBox-Figure{
    font-size: 20px;
    font-weight: 600;
}

.lykiocss-featuredInfoBox-FigureRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.lykiocss-featuredInfoBox-Icon{
    font-size: 14px;
    margin-left: 5px;
    color: green;
}

.lykiocss-featuredInfoBox-Sub{
    font-size: 15px;
    color: gray;
}